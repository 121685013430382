<template>
  <div class="create-order f-w3 cancellation-survey">
    <div class="">
      <div class="cancel-content">
        <p>
          サービス改善の為、解約の理由を教えてください。(複数選択可)
        </p>
      </div>
      <div class="cancel-option">
        <div v-for="(option, index) in cancelOptions" :key="index">
          <div
            class="custom-control custom-checkbox cancel-item"
            v-if="
              (option.id != 1 && packageActive == 1) ||
                (option.id != 0 && packageActive == 0)
            "
          >
            <input
              class="custom-control-input"
              type="checkbox"
              :value="option.content"
              :id="'option_' + option.id"
              v-model="optionLists"
            />
            <label class="custom-control-label" :for="'option_' + option.id">
              {{ option.content }}
            </label>
          </div>
        </div>
      </div>
      <div class="other-option">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text f-w3" id="basic-addon3">その他:</span>
          </div>
          <input
            type="text"
            class="form-control cancel-option-input"
            autocomplete="off"
            v-model="otherOption"
            v-on:input="checkLength"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
      </div>
      <div class="btn-send-survey">
        <button type="button" class="btn btn-secondary" @click="sendSurvey">
          送信
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import "../assets/scss/cancellationSurvey.scss";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CancellationSurveyPopup",
  data() {
    return {
      cancelOptions: [
        {
          id: 0,
          content: "料金が高いと感じたから"
        },
        {
          id: 1,
          content: "お金を払うほどのサービスではなかったから"
        },
        {
          id: 2,
          content: "サービスが必要ではなくなったから"
        },
        {
          id: 3,
          content: "マッチングできないから"
        },
        {
          id: 4,
          content: "良い相手がいないから"
        }
      ],
      optionLists: [],
      otherOption: ""
    };
  },
  props: {
    packageActive: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions("auth", ["getUsersPackage", "getInfoUser"]),
    checkLength(event) {
      if (event.target.value.length > 20) {
        this.otherOption = event.target.value.substring(0, 20);
      }
    },
    sendSurvey() {
      if (
        this.otherOption.length > 20 ||
        (this.optionLists.length == 0 && this.otherOption.length == 0)
      ) {
        this.$toast("解約理由をお選びください", "不合格", "danger");
      } else {
        this.$root.$refs.loading.start();
        const params = {
          otherOption: this.otherOption,
          optionLists: this.optionLists
        };
        this.$parent.$store
          .dispatch("payment-stripe/cancelPaymentPacket", params)
          .then(() => {
            this.$toast("サブスクを解除しました。", "通知", "success");
            this.$emit("close-modal");
            this.$emit("cancel-success");
            this.getInfoUser();
            this.getUsersPackage();
            this.$root.$refs.loading.finish();
          })
          .catch(() => {
            this.$toast("キャンセルの失敗。", "通知", "danger");
            this.$root.$refs.loading.finish();
          });
      }
    }
  }
};
</script>
