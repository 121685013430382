<template>
  <div id="men-setting-account-id">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="content">
        <router-link
          v-for="(item, index) in tabOptions"
          event=""
          :to="item.routeName"
          @click.native.prevent="handleOpenModal(item.routeName)"
          :index="index"
          :key="item.id"
          :id="item.id"
          class="
          d-flex
          justify-content-between
          align-items-center
          content__item
          "
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3">{{ item.name }}</span>
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </router-link>
        <div
          class="
          d-flex
          justify-content-between
          align-items-center
          content__item
          "
          @click="packageCancelModal()"
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3"
              >サブスクリプションの解除</span
            >
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </div>
        <div
          class="
          d-flex
          justify-content-between
          align-items-center
          content__item
          "
          @click="$refs.modalDeleteUser.openModal()"
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3">退会の申請</span>
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </div>
      </div>
    </div>
    <ModalLayout ref="modalPackageCancel">
      <p class="modal-logout-title f-w3">
        {{ getTextpopup() }}
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button
          @click.prevent="packageCancelSurvey()"
          class="btn-cancel-logout"
        >
          はい
        </button>
        <button
          @click="$refs.modalPackageCancel.closeModal()"
          class="btn-logout"
        >
          いいえ
        </button>
      </div>
    </ModalLayout>
    <ModalLayout ref="modalDeleteUser">
      <p class="modal-logout-title f-w3">
        アプリ内に登録されている情報やポイント等すべて消えてしまいます。<br />
        {{ user.sex === 1 ? "残ポイント" : "残コイン" }}({{
          Intl.NumberFormat()
            .format(
              user.sex === 1 ? user.pg_points_balance : user.bd_points_balance
            )
            .replace(/\./g, ",")
        }})<br />
        このまま退会しますか？
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button
          @click.prevent="
            $refs.modalAcceptDeleteUser.openModal();
            $refs.modalDeleteUser.closeModal();
          "
          class="btn-cancel-logout"
        >
          はい
        </button>
        <button @click="$refs.modalDeleteUser.closeModal()" class="btn-logout">
          いいえ
        </button>
      </div>
    </ModalLayout>
    <ModalLayout ref="modalAcceptDeleteUser">
      <p class="modal-logout-title f-w3">
        本当に退会しますか？
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button @click.prevent="deleteUser()" class="btn-cancel-logout">
          はい
        </button>
        <button
          @click="$refs.modalAcceptDeleteUser.closeModal()"
          class="btn-logout"
        >
          いいえ
        </button>
      </div>
    </ModalLayout>
    <ModalLayout ref="addMonthlyPlanPopup">
      <AddMonthlyPlanPopup
        @close-modal="closeAddMonthlyPlanPopup"
        @add-success="addSuccess"
        :routePage="'NotMessage'"
      />
    </ModalLayout>
    <ModalLayout ref="cancellationSurveyPopup">
      <CancellationSurveyPopup
        :packageActive="getPackageActive"
        @close-modal="closeCancellationSurveyPopup"
        @cancel-success="cancelSuccess"
      />
    </ModalLayout>
    <ModalLayout ref="modalInvalidCard">
      <p class="modal-logout-title f-w3">
        クレジットカードが無効の為、アカウントを削除できません。<br />
        有効なクレジットカードをご入力いただき、認証後アカウントを削除頂けます。
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button @click="addCard" class="btn-logout btn-card">
          クレジットカード登録
        </button>
      </div>
    </ModalLayout>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter";
import "../../assets/scss/men/settingAccount.scss";
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import firebase from "firebase";
import AddMonthlyPlanPopup from "@/components/AddMonthlyPlanPopup.vue";
import CancellationSurveyPopup from "@/components/CancellationSurveyPopup.vue";
export default {
  name: "SettingAccount",
  components: {
    CardHeader,
    CardFooter,
    AddMonthlyPlanPopup,
    CancellationSurveyPopup
  },
  data() {
    return {
      headerTitle: {
        text: "アカウント",
        isShowTitle: true,
        isShowLogo: false,
        back: null,
        backTo: "/my-page/setting"
      },
      tabOptions: [],
      cancelPackageSuccess: false
    };
  },
  mounted() {
    this.tabOptions = [
      {
        id: "package-info",
        name: "契約の情報",
        routeName: "package-info"
      },
      {
        id: "card-register-info",
        name: "クレジットカードの情報・登録",
        routeName: !this.cardInfo ? "register-card-credit" : "info-card-credit"
      },
      {
        id: "men-change-pass",
        name: "パスワード更新",
        routeName: "change-password"
      }
    ];
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      cardInfo: "payment-stripe/cardInfo",
      monthlyPlanStatus: "payment-stripe/monthlyPlanStatus",
      package: "auth/package"
    }),
    getPackageActive() {
      return this.package.package_pay_active;
    }
  },
  async created() {
    await this.getPaymentMonthPacket();
    await this.getUsersPackage();
  },
  methods: {
    ...mapActions("payment-stripe", [
      "getPaymentMonthPacket",
      "cancelPaymentPacket"
    ]),
    ...mapActions("auth", ["getUsersPackage"]),
    getTextpopup() {
      if (this.package.time_remain == 0) {
        return (
          "今解約すると" +
          this.package.number_female +
          "人とのマッチングチャンスを失いますが、よろしいですか？"
        );
      } else {
        return (
          "あと" +
          this.package.time_remain +
          "日残っています。今解約すると約" +
          this.package.number_female +
          "人とのマッチングチャンスを失いますが、よろしいですか？"
        );
      }
    },
    async packageCancelModal() {
      if (!this.monthlyPlanStatus || this.cancelPackageSuccess) {
        this.$refs.addMonthlyPlanPopup.openModal();
        return;
      } else {
        this.$refs.modalPackageCancel.openModal();
      }
    },
    async deleteUser() {
      this.$root.$refs.loading.start();
      if (this.user.str_customer_id && this.monthlyPlanStatus) {
        await this.$store
          .dispatch("payment-stripe/cancelPaymentPacketDeleteUser")
          .then(() => {
            this.deleteUserAction();
          })
          .catch(() => {
            this.$refs.modalAcceptDeleteUser.closeModal();
            this.$root.$refs.loading.finish();
            this.$refs.modalInvalidCard.openModal();
          });
      } else {
        this.deleteUserAction();
      }
    },
    deleteUserAction() {
      this.$store
        .dispatch("userApp/deleteUser")
        .then(response => {
          this.$root.$refs.loading.finish();
          if (response?.message && !response.success) {
            this.$refs.modalAcceptDeleteUser.closeModal();
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          Cookies.remove("prego_token");
          firebase.auth().signOut();
          this.$router.push({
            name: "TopRegister",
            params: { delete: "success" }
          });
          // this.$toast("宛名数と人数が異なります。", "不合格", "success");
        })
        .catch(() => {
          this.$refs.modalAcceptDeleteUser.closeModal();
          this.$toast("ユーザーの削除に失敗しました", "不合格", "danger");
          this.$root.$refs.loading.finish();
        });
    },
    async packageCancelSurvey() {
      this.$refs.modalPackageCancel.closeModal();
      this.$refs.cancellationSurveyPopup.openModal();
    },
    handleOpenModal(routeName) {
      if (
        routeName == "package-info" &&
        (!this.monthlyPlanStatus || this.cancelPackageSuccess)
      ) {
        this.$refs.addMonthlyPlanPopup.openModal();
        return;
      } else {
        this.$router.push(routeName);
      }
    },
    async closeAddMonthlyPlanPopup() {
      this.$refs.addMonthlyPlanPopup.closeModal();
    },
    async closeCancellationSurveyPopup() {
      this.$refs.cancellationSurveyPopup.closeModal();
    },
    async cancelSuccess() {
      this.cancelPackageSuccess = true;
    },
    async addSuccess() {
      this.cancelPackageSuccess = false;
    },
    addCard() {
      this.$router.push({
        name: "MenRegisterCardCredit",
        params: { isDeleteUser: true }
      });
    }
  }
};
</script>

<style scoped lang="scss">
#men-setting-account-id {
  /deep/.app-modal.default .app-modal__dialog {
    width: 375px;
  }
  .btn-card {
    width: auto;
    padding: 0 16px;
  }
}
@media only screen and (max-width: 376px) {
  .app-modal.active {
    overflow: auto;
    padding-top: 70px;
  }
}
</style>
